import useTranslation from "@/hooks/useTranslation";
import { breadcrumbNameMap } from "@/routes";
import { Breadcrumb as AntBreadCrumb } from "antd";
import { css } from "emotion";
import Link from "next/link";
import { useRouter } from "next/router";

const noUrl = [
  "/explore",
  "/bundle",
  "/navigator",
  "/navigator/paper",
  "/navigator/journal",
];

function Breadcrumb() {
  const router = useRouter();
  const { t } = useTranslation("menu");
  const pathSnippets = router.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (breadcrumbNameMap[url]) {
      return (
        <AntBreadCrumb.Item key={url}>
          {noUrl?.includes(url) ? (
            t(breadcrumbNameMap[url])
          ) : (
            <Link href={url}>
              <a>{t(breadcrumbNameMap[url])}</a>
            </Link>
          )}
        </AntBreadCrumb.Item>
      );
    }
  });
  const breadcrumbItems = [
    <AntBreadCrumb.Item key="home">
      <Link href="/">
        <a>{t("home")}</a>
      </Link>
    </AntBreadCrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <div
      className={css`
        font-weight: 600;
      `}
    >
      <AntBreadCrumb>{breadcrumbItems}</AntBreadCrumb>
    </div>
  );
}

export default Breadcrumb;
