import guestIcon from "@/assets/images/svg/guest.svg";
import HeaderDropdown from "@/components/HeaderDropdown";
import { IconBarLogin, IconBarRegister } from "@/components/IconFont";
import useTranslation from "@/hooks/useTranslation";
import { currentUserState, routerState } from "@/stores/common";
import { removeToken } from "@/utils/cookies.util";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Menu } from "antd";
import { useRouter } from "next/router";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import DynamicMyAvatar from "../MyAvatar/DynamicMyAvatar";
import styles from "./styles.module.less";
const AvatarDropdown: React.FC = () => {
  const currentUser = useRecoilValue(currentUserState);
  const router = useRouter();
  const { t } = useTranslation("menu");
  const setRouterState = useSetRecoilState(routerState);

  const onMenuClick = (event) => {
    const { key } = event;

    switch (key) {
      case "logout":
        removeToken();
        router.reload();
        return;
      case "login":
        setRouterState({ redirect: router.asPath });
        router.push("/auth/login");
        return;
      case "register":
        setRouterState({ redirect: router.asPath });
        router.push("/auth/register");
        return;
      default:
        router.push(`/user/${key}`);
    }
  };

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="dashboard">
        <UserOutlined />
        {t("user.dashboard")}
      </Menu.Item>
      <Menu.Item key="settings">
        <SettingOutlined />
        {t("user.settings")}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
        {t("auth.logout")}
      </Menu.Item>
    </Menu>
  );

  const guestHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="login">
        <IconBarLogin className={styles.barIcon} />
        Login
      </Menu.Item>
      <Menu.Item key="register">
        <IconBarRegister className={styles.barIcon} />
        Sign up
      </Menu.Item>
    </Menu>
  );

  return currentUser?.id ? (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <DynamicMyAvatar
          className={styles.avatar}
          src={currentUser.avatarUrl}
          alt="avatar"
        />
        <span className={styles.name} style={{ fontSize: "1.2rem" }}>
          <span style={{ color: "#595959" }}>Hi, </span>
          <span style={{ color: "#C2185B" }}>{currentUser.username}</span>
        </span>
      </span>
    </HeaderDropdown>
  ) : (
    <HeaderDropdown overlay={guestHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar
          size="default"
          className={styles.avatar}
          src={guestIcon}
          alt="avatar"
        />
        <span className={styles.name} style={{ fontSize: "1.2rem" }}>
          <span style={{ color: "#595959" }}>Hi, </span>
          <span style={{ color: "#C2185B" }}>Guest</span>
        </span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
