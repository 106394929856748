import React from "react";
import styles from "./styles.module.less";

const { container, content } = styles;

const Footer = () => {
  return (
    <div className={container}>
      <div className={content}>
        <div>
          © 2017 - {new Date().getFullYear()} <strong>Medicgo</strong>
        </div>
        <div>Powered by some medical students
        </div>
      </div>
    </div>
  );
};

export default Footer;
