import { IRoutes } from "@/interfaces/routes.interface";

const routes: IRoutes = [
  { name: "home", path: "/", icon: "home" },
  {
    name: "explore.itself",
    path: "/explore",
    icon: "explore",
    routes: [
      { name: "explore.navi", path: "/explore/navi", icon: "navi" },
      { name: "explore.pubmed", path: "/explore/pubmed", icon: "pubmed" },
      { name: "explore.journal", path: "/explore/journal", icon: "journal" },
    ],
  },
  {
    name: "bundle.itself",
    path: "/bundle",
    icon: "bundle",
    routes: [
      {
        name: "bundle.subscription",
        path: "/bundle/subscription",
        icon: "subscription",
      },
      {
        name: "bundle.collection",
        path: "/bundle/collection",
        icon: "collection",
      },
      {
        name: "bundle.directory",
        path: "/bundle/directory",
        icon: "directory",
      },
      {
        name: "bundle.cart",
        path: "/bundle/cart",
        icon: "cart",
        notShowInMenu: true,
      },
    ],
  },
  {
    name: "about.itself",
    path: "/about",
    icon: "about",
    routes: [
      { name: "about.chat", path: "/about/chat", icon: "chat" },
      { name: "about.feedback", path: "/about/feedback", icon: "contact" },
      {
        name: "about.docs",
        path: "/about/docs",
        icon: "docs",
      },
    ],
  },
  {
    name: "navigator.itself",
    path: "/navigator",
    routes: [
      { name: "navigator.paper", path: "/navigator/paper" },
      { name: "navigator.journal", path: "/navigator/journal" },
    ],
    notShowInMenu: true,
  },
  {
    name: "user.itself",
    path: "/user",
    notShowInMenu: true,
    icon: "user",
    routes: [
      { name: "user.dashboard", path: "/user/dashboard", icon: "dashboard" },
      { name: "user.profile", path: "/user/profile", icon: "profile" },
      { name: "user.settings", path: "/user/settings", icon: "settings" },
      { name: "user.password", path: "/user/password", icon: "password" },
    ],
  },
];

export const breadcrumbNameMap = routes
  .flatMap((route) => {
    const { routes = [], ...r } = route;
    return [...routes, r];
  })
  .map(({ name, path }) => ({ [path]: name }))
  .reduce((a, b) => ({ ...a, ...b }), {});

export default routes;
