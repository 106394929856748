import React from "react";
import SelectLang from "../SelectLang";
import Avatar from "./AvatarDropdown";
import styles from "./styles.module.less";

const GlobalHeaderRight: React.FC<{}> = () => {
  return (
    <div className={styles.right}>
      <Avatar />
      <SelectLang className={styles.action} />
    </div>
  );
};
export default GlobalHeaderRight;
