import { createFromIconfontCN } from "@ant-design/icons";
import React from "react";

const IconFont = createFromIconfontCN({
  scriptUrl: "/iconfont.js",
});

interface IIcon {
  className?: any;
}

export const IconLogin: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconLogin" />
);

export const IconRegister: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconzhuce" />
);

export const IconLogo: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconpaper" />
);

export const IconFilter: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconFilter" />
);

export const IconWorkSpace: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconworkspace" />
);

export const IconFavList: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="icon-listEmpty" />
);

export const IconBarLogin: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="icondenglu1" />
);

export const IconBarRegister: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconzhuce2" />
);

export const IconReview: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconr" />
);

export const IconMeta: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconicon-test" />
);

export const IconClinicalTrial: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconzu" />
);

export const IconGuideline: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconserver_01" />
);

export const IconMultiCenter: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconyiyuan" />
);

export const IconEditorial: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconzujian" />
);
export const IconLetter: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconletter" />
);

export const IconRCT: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconrandom" />
);

export const IconCase: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconren" />
);

export const IconClinical: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconlinchuangshiyan" />
);

export const IconDefaultType: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconkeyansuo" />
);

export const IconSearch: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconsuosou" />
);

export const IconFilters: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconguolv" />
);
export const IconHistory: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconlishi2" />
);
export const IconContent: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconpaiban" />
);

export const IconAffiliation: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconjigou1" />
);
export const IconKeyword: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconbowenguanjianci" />
);
export const IconDownload: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="icondownload1" />
);
export const IconFav: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconFavourite" />
);
export const IconComment: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconpinglun" />
);
export const IconShare: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconshareed" />
);
export const IconCitation: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconyinyong" />
);
export const IconExport: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconexport" />
);

export const IconCycle: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconzhouqi" />
);

export const IconNation: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconguojia" />
);

export const IconImpact: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconyinzifenbu" />
);

export const IconWeb: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconwangzhan" />
);

export const IconPublisher: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconPublisher" />
);

export const IconBundle: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconBundle-" />
);

export const IconP: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconp1" />
);

export const IconN: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconN" />
);

export const IconInitial: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconmingcheng" />
);

export const IconISSN: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconbianhao" />
);

export const IconSince: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconlishi3" />
);

interface OAType extends IIcon {
  oa: boolean;
}

export const IconOA: React.FC<OAType> = ({ className, oa }) => {
  if (oa) {
    return <IconFont className={className} type="iconkaifang" />;
  }
  return <IconFont className={className} type="iconsuo" />;
};

export const IconCat: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconxiangmuleibie" />
);

export const IconMenu: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconmenu" />
);

export const IconNavi: React.FC<IIcon> = ({ className }) => (
  <IconFont className={className} type="iconwenzhang" />
);
