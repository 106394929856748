import Breadcrumb from "../Breadcrumb";
import styles from "./styles.module.less";

function Banner() {
  return (
    <div className={styles.banner}>
      <div className={styles.container}>
        <Breadcrumb />
      </div>
    </div>
  );
}

export default Banner;
