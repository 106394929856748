import useTranslation from "@/hooks/useTranslation";
import { GlobalOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import classNames from "classnames";
import React from "react";
import HeaderDropdown from "../HeaderDropdown";
import styles from "./styles.module.less";

interface SelectLangProps {
  className?: string;
}

const SelectLang: React.FC<SelectLangProps> = (props) => {
  const { className } = props;

  const { i18n } = useTranslation();

  const selectedLang = i18n.language;

  const changeLang = ({ key }) => i18n.changeLanguage(key);

  const locales = ["zh-CN", "en"];
  const languageLabels = {
    ["zh-CN"]: "简体中文",
    en: "English",
  };
  const languageIcons = {
    "zh-CN": "🇨🇳",
    en: "🇺🇸",
  };
  const langMenu = (
    <Menu
      className={styles.menu}
      selectedKeys={[selectedLang]}
      onClick={changeLang}
    >
      {locales.map((locale) => (
        <Menu.Item key={locale}>
          <span role="img">{languageIcons[locale]}</span>{" "}
          {languageLabels[locale]}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <HeaderDropdown overlay={langMenu} placement="bottomRight">
      <span className={classNames(styles.dropDown, className)}>
        <GlobalOutlined title="语言" />
      </span>
    </HeaderDropdown>
  );
};

export default SelectLang;
