import paperSvg from "@/assets/images/svg/paper.svg";
import RightContent from "@/components/RightContent";
import useTranslation from "@/hooks/useTranslation";
import routes from "@/routes";
import {
  ContactsOutlined,
  DatabaseOutlined,
  ForkOutlined,
  GroupOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  SearchOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { IconButton } from "@material-ui/core";
import { Drawer, Menu } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  IconBundle,
  IconComment,
  IconImpact,
  IconP,
  IconSearch,
} from "../IconFont";
import styles from "./styles.module.less";

const headerIconMap = {
  home: <HomeOutlined />,
  explore: <SearchOutlined />,
  bundle: <GroupOutlined />,
  about: <ForkOutlined />,
  pubmed: <IconP />,
  journal: <IconImpact />,
  navi: <IconSearch />,
  subscription: <IconBundle />,
  chat: <MessageOutlined />,
  contact: <ContactsOutlined />,
  directory: <DatabaseOutlined />,
  collection: <IconComment />,
  docs: <SnippetsOutlined />,
};

function Header() {
  const { t } = useTranslation("menu");
  const router = useRouter();
  const [visible, setVisible] = useState(false);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <div className={styles.logo} onClick={() => router.push("/")}>
            <img src={paperSvg} />
          </div>
          <div className={styles.logoText} onClick={() => router.push("/")}>
            MedicGo
          </div>
          <div className={styles.menuBar} onClick={() => setVisible(!visible)}>
            <IconButton>
              <MenuUnfoldOutlined />
            </IconButton>
          </div>
        </div>
        <div className={styles.menuContainer}>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["/"]}
            selectedKeys={[router.pathname]}
          >
            {routes
              .filter((route) => !route.notShowInMenu)
              .map((route) => {
                if (route.routes) {
                  return (
                    <Menu.SubMenu
                      title={t(route.name)}
                      key={route.path}
                      icon={headerIconMap[route.icon]}
                    >
                      {route.routes
                        .filter((r) => !r.notShowInMenu)
                        .map((r) => (
                          <Menu.Item key={r.path} icon={headerIconMap[r.icon]}>
                            <Link href={r.path}>
                              <a>{t(r.name)}</a>
                            </Link>
                          </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                  );
                }
                return (
                  <Menu.Item key={route.path} icon={headerIconMap[route.icon]}>
                    <Link href={route.path}>
                      <a>{t(route.name)}</a>
                    </Link>
                  </Menu.Item>
                );
              })}
          </Menu>
        </div>
        <div className={styles.rightContent}>
          <RightContent />
        </div>
        <Drawer
          title={
            <div
              className={styles.logoContainer}
              style={{ marginLeft: "0.25rem" }}
            >
              <div className={styles.logo}>
                <img src={paperSvg} />
              </div>
              <div className={styles.logoText} style={{ display: "block" }}>
                MedicGo
              </div>
            </div>
          }
          placement="left"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          className={styles.drawerContainer}
        >
          <div>
            <Menu
              mode="inline"
              inlineCollapsed={false}
              defaultSelectedKeys={["/"]}
              selectedKeys={[router.pathname]}
            >
              {routes
                .filter((route) => !route.notShowInMenu)
                .map((route) => {
                  if (route.routes) {
                    return (
                      <Menu.SubMenu
                        title={t(route.name)}
                        key={route.path}
                        icon={headerIconMap[route.icon]}
                      >
                        {route.routes
                          .filter((route) => !route.notShowInMenu)
                          .map((r) => (
                            <Menu.Item
                              key={r.path}
                              icon={headerIconMap[r.icon]}
                            >
                              <Link href={r.path}>
                                <a>{t(r.name)}</a>
                              </Link>
                            </Menu.Item>
                          ))}
                      </Menu.SubMenu>
                    );
                  }
                  return (
                    <Menu.Item
                      key={route.path}
                      icon={headerIconMap[route.icon]}
                    >
                      <Link href={route.path}>
                        <a>{t(route.name)}</a>
                      </Link>
                    </Menu.Item>
                  );
                })}
            </Menu>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default Header;
